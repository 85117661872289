// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-fs-about-js": () => import("./../../../src/pages-fs/about.js" /* webpackChunkName: "component---src-pages-fs-about-js" */),
  "component---src-pages-fs-contact-js": () => import("./../../../src/pages-fs/contact.js" /* webpackChunkName: "component---src-pages-fs-contact-js" */),
  "component---src-pages-fs-features-js": () => import("./../../../src/pages-fs/features.js" /* webpackChunkName: "component---src-pages-fs-features-js" */),
  "component---src-pages-fs-home-js": () => import("./../../../src/pages-fs/home.js" /* webpackChunkName: "component---src-pages-fs-home-js" */),
  "component---src-pages-fs-index-js": () => import("./../../../src/pages-fs/index.js" /* webpackChunkName: "component---src-pages-fs-index-js" */),
  "component---src-pages-fs-legal-js": () => import("./../../../src/pages-fs/legal.js" /* webpackChunkName: "component---src-pages-fs-legal-js" */),
  "component---src-templates-case-page-js": () => import("./../../../src/templates/case-page.js" /* webpackChunkName: "component---src-templates-case-page-js" */)
}

